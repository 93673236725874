// Styling for the contact section
section#contact {
  background-repeat: no-repeat;
  background-position: center;
  .section-heading {
    color: $white;
  }
  .form-group {
    margin-bottom: 10px;
    input,
    textarea {
      padding: 20px;
      border-radius: 10px;
      background-color: $gray-100
    }
    input.form-control {
      height: auto;
    }
    textarea.form-control {
      height: 311px;
    }

  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 500;
    color: $gray-400;
    @include heading-font;
  }
  :-moz-placeholder {
    font-weight: 300;
    color: $gray-400;
    /* Firefox 18- */
    @include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 300;
    color: $gray-400;
    /* Firefox 19+ */
    @include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 400;
    color: $gray-400;
    @include heading-font;
  }
  .btn-separator:after {
    content: ' ';
    display: block;
    float: left;
    background: #ADADAD;
    margin: 0;
    height: 100%;
    width: 0px;
  }
}

#tipo1 {
  border-radius: 10px 0px 0px 10px
}

#tipo2 {
  border-radius: 0px 10px 10px 0px
}

#tipo1, #tipo2 {
  width: 50%
}

#sendMessageButton {
  border-radius: 10px;
  background-color: $cuaternary;
  font-weight: bold;
}