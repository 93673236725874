// Styling for the footer
footer {
  padding: 25px 0;
  text-align: center;
  span.copyright {
    font-size: 90%;
    line-height: 40px;
    text-transform: none;
    @include heading-font;
  }
  ul.quicklinks {
    font-size: 90%;
    line-height: 40px;
    margin-bottom: 0;
    text-transform: none;
    @include heading-font;
  }
}

ul.social-buttons {
  margin-bottom: 0;
  li {
    a {
      font-size: 20px;
      line-height: 40px;
      display: block;
      height: 40px;
      width: 40px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      color: white;
      outline: none;
    }
  }
}

.content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
