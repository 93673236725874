// Styling for the services section
.service-heading {
  margin: 15px 0;
  text-transform: none;
}

.flourish {
  display: block;
  text-align: center;
}

div.flourish-col {
  overflow: hidden;
}

.flourish:before, 
.flourish:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 50%;
  border-top-style: dotted;
  border-top-width: 2px;
}
.flourish:before {
  right: 0.5em;
  margin-left: -50%;
}
.flourish:after {
  left: 0.5em;
  margin-right: -50%;
}

.flourish img {
  position: absolute;
  left: 0%
}
.flourish-primary {
	color: $primary;
}
.flourish-secondary {
	color: $secondary;
}
.flourish-terciary {
	color: $terciary;
}
.flourish-cuaternary {
	color: $cuaternary;
}
.flourish-white {
	color: white;
}

#empresas {
	background-color: $primary;
}

#personas {
	background-color: $cuaternary;
}